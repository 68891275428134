// subscriptionUtils.js

export const executeSubscriptionAction = async (endpoint) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const subsId = urlParams.get("id");
  
      const codeParam = "S-86S8dQ9uKs0HscnkPO1z-l5LlEcR4YclAHQ8PVlB7eAzFu88gfhA%3D%3D";
      const fullUrl = `${endpoint}?code=${codeParam}`;
  
      const response = await fetch(fullUrl, {
        method: "POST",
        headers: {
          "subscription-id": subsId,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Error: " + response.statusText);
      }
  
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  export const activateSubscription = async() => {
    const activateEndpoint = "https://app-af-webhook-vr-dev.azurewebsites.net/api/activatesubscription";
    await executeSubscriptionAction(activateEndpoint);
  };
  
  export const cancelSubscription = async () => {
    const cancelEndpoint = "https://app-af-webhook-vr-dev.azurewebsites.net/api/cancelsubscription";
    await executeSubscriptionAction(cancelEndpoint);
  };
  